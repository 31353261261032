// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-article-page-tsx": () => import("./../../../src/components/ArticlePage.tsx" /* webpackChunkName: "component---src-components-article-page-tsx" */),
  "component---src-pages-categorie-s-strapi-sous-categories-slug-tsx": () => import("./../../../src/pages/categorie/s/{StrapiSousCategories.Slug}.tsx" /* webpackChunkName: "component---src-pages-categorie-s-strapi-sous-categories-slug-tsx" */),
  "component---src-pages-categorie-strapi-categories-slug-tsx": () => import("./../../../src/pages/categorie/{StrapiCategories.Slug}.tsx" /* webpackChunkName: "component---src-pages-categorie-strapi-categories-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nous-trouver-tsx": () => import("./../../../src/pages/nous-trouver.tsx" /* webpackChunkName: "component---src-pages-nous-trouver-tsx" */),
  "component---src-pages-strapi-pages-slug-tsx": () => import("./../../../src/pages/{StrapiPages.Slug}.tsx" /* webpackChunkName: "component---src-pages-strapi-pages-slug-tsx" */)
}

